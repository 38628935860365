import React from 'react';
import { PriceCard } from '../Components/PriceCard';

const CableTV = () => {
  const tcclPlan = [
    {
      type: 'medium',
      name: 'TCCL VILLAGE SD PACK',
      price: 160,
      mostPopular: false,
      description: '',
      GST: 0,
      data: ['100+ Tamil Channels', '30+ Local Channels'],
    },
    {
      type: 'medium',
      name: 'TCCL CLASSIC SD  ',
      price: 200,
      mostPopular: true,
      description: '',
      GST: 0,
      data: [
        '250+ Tamil Channels',
        '30+ Local Channels',
        '20+ Sports Channels',
        '100+ Other Language Channels',
      ],
    },
    {
      type: 'medium',
      name: 'TCCL CLASSIC HD',
      price: 300,
      mostPopular: false,
      description: '',
      GST: 0,
      data: [
        '30+ HD Channels',
        '250+ Tamil Channels',
        '30+ Local Channels',
        '20+ Sports Channels',
        '100+ Other Language Channels',
      ],
    },
  ];
  return (
    <>
      <section className="pricing-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-about-box about-details">
                <div className="icon">
                  {/* <span class="material-icons">tv</span> */}
                  <i class="fa fa-tv"></i>
                </div>
                <h3>Premium & More channels</h3>
                {/* <p>400+ TV channels* across different genres and languages.</p> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-about-box about-details">
                <div className="icon">
                  <i class="fa fa-cloud-sun-rain"></i>
                </div>
                <h3>Weather Proof Service</h3>
                <p>
                  {/* Weather proof service unaffected by rain interruptions ensuring an uninterrupted
                  viewing experience. */}
                </p>
              </div>
            </div>
          </div>
          <div className="section-title">
            <h2>Discover our best plans</h2>
            <h4> TCCL plans</h4>
          </div>
          <div className="row pricing-plans">
            {Array.isArray(tcclPlan) &&
              tcclPlan.map((plan) => {
                return (
                  <PriceCard
                    name={plan.name}
                    data={plan.data}
                    price={plan.price}
                    GST={plan.GST}
                    ott={plan.ott}
                    mostPopular={plan.mostPopular}
                  />
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CableTV;
