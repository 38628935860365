/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

function getContainsURL(childUrl, pathName, queryParams) {
  if (Array.isArray(childUrl) && pathName) {
    return childUrl.some((item) => {
      const isPathURL = pathName.includes(item); // if pathName match return true, otherwise will check with pathName and queryParams
      return !isPathURL && queryParams ? `${pathName}${queryParams}`.includes(item) : isPathURL;
    });
  }
  return false;
}

export const Navbar = (props) => {
  const items = [
    {
      id: 'home',
      title: 'Home',
      url: '/',
    },
    {
      id: 'cableTv',
      title: 'Cable TV',
      url: '/cableTv',
      childUrl: ['/cableTv'],
    },
    {
      id: 'broadband',
      title: 'Broadband',
      url: '/broadband',
      childUrl: ['/broadband'],
    },
    {
      id: 'aboutUs',
      title: 'About Us',
      url: '/aboutUs',
      childUrl: ['/aboutUs'],
    },
    {
      id: 'blog',
      title: 'Blog',
      url: '/blog',
      childUrl: ['/blog'],
    },
    {
      id: 'contact',
      title: 'Contact',
      url: '/contact',
      childUrl: ['/contact'],
    },
  ];
  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 96);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const navigate = useNavigate();
  const handleClick = (pathName) => {
    navigate(pathName);
  };

  const mobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header className="header-area">
      <div className={isScrollValueMoreThanHeaderHeight ? 'navbar-area is-sticky' : 'navbar-area'}>
        <div className={isMobile ? 'raj-mobile-nav mean-container' : 'raj-mobile-nav'}>
          {isMobile && (
            <div className="mean-bar">
              <a
                onClick={() => mobileMenuClick()}
                className={showMobileMenu ? 'meanmenu-reveal meanclose' : 'meanmenu-reveal'}
                style={{
                  right: '0px',
                  left: 'auto',
                  textAlign: 'center',
                  textIndent: '0px',
                  fontSize: '18px',
                }}
              >
                {!showMobileMenu && (
                  <span>
                    <span>
                      <span></span>
                    </span>
                  </span>
                )}
              </a>
              <nav className="mean-nav">
                <ul className="navbar-nav" style={showMobileMenu ? {} : { display: 'none' }}>
                  {items.map((item, index) => {
                    let containUrl = false;
                    if (item.hasOwnProperty('childUrl')) {
                      const pathName = window.location.pathname;
                      const queryParams = window.location.search;
                      containUrl = getContainsURL(item.childUrl, pathName, queryParams);
                    }
                    const isLastItem = items.length - 1;
                    let className = containUrl ? 'nav-item active' : 'nav-item';
                    className = isLastItem === index && className + 'mean-last';
                    return (
                      <li
                        style={{
                          cursor: 'pointer',
                        }}
                        className={className}
                        onClick={(e) => {
                          mobileMenuClick();
                          // handleClick(item.url);
                        }}
                      >
                        <Link to={item.url} className="nav-link">
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <div className="others-options">
                  <a className="btn btn-primary" href="/contact">
                    Get Started
                  </a>
                </div>
              </nav>
            </div>
          )}
          <div className="logo">
            <a href="/">
              <img className src="/img/logo.png" style={{ width: '60px' }} alt="logo" />
              {/* <span style={{ color: 'white' }}>Raj Cable TV</span> */}
            </a>
          </div>
        </div>
        <div className="raj-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="/">
                <img className src="/img/logo.png" alt="logo" style={{ width: '100px' }} />
                {/* <span style={{ color: '#fffff' }}>Raj Cable TV</span> */}
              </a>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: 'block' }}
              >
                <ul className="navbar-nav">
                  {items.map((item) => {
                    let containUrl = false;
                    if (item.hasOwnProperty('childUrl')) {
                      const pathName = window.location.pathname;
                      const queryParams = window.location.search;
                      containUrl = getContainsURL(item.childUrl, pathName, queryParams);
                    }
                    return (
                      <li
                        key={item.id}
                        className={containUrl ? 'nav-item active' : 'nav-item'}
                        // onClick={(e) => handleClick(item.url)}
                      >
                        <Link to={item.url} href="" className="nav-link">
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <div className="others-options">
                  <a className="btn btn-primary" href="/contact">
                    Get Started
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
