import React from 'react';

export const SingleFeaturesCard = (props) => {
  const { icon, title, description } = props;
  return (
    <div className="single-features-box match-height">
      <div className="icon">
        <i className={icon}></i>
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="image-box">
        <img src="img/2.png" alt="image" />
        <img src="img/2.png" alt="image" />
      </div>
    </div>
  );
};
