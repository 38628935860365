/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
  const { id, imageUrl, title, description } = props;
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-blog-post">
        <a className="post-image" href={`blog/${id}`}>
          <img src={imageUrl} alt="blog-image" />
        </a>
        <div className="blog-post-content">
          <ul>
            <li>
              <a href="">Admin</a>
            </li>
            <li>
              <i className="far fa-clock"></i> January 23, 2020
            </li>
          </ul>
          <h3>
            <a href={`blog/${id}`}>{title}</a>
          </h3>
          <p>{description}</p>
          <Link className="read-more-btn" to={`blog/${id}`}>
            Read More <i className="fas fa-angle-double-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Blog = () => {
  const blogs = [
    {
      id: 'how-to-change-wifi-password',
      imageUrl: 'img/banner-img1.png',
      title: 'How to changes WiFi Name and Password',
      description:
        'Learn how to change your WiFi network name and password on your computer or mobile.',
    },
  ];
  return (
    <section className="blog-area ptb-100">
      <div className="container">
        <div className="row">
          {blogs.map((blog) => (
            <BlogCard
              id={blog.id}
              imageUrl={blog.imageUrl}
              title={blog.title}
              description={blog.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
