import React from 'react';
import { SingleFeaturesCard } from './Single-features-card';

export const Features = () => {
  return (
    <section className="features-area bg-image ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>We are internet service provider in Serndamaram</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <SingleFeaturesCard
              icon="flaticon-speedometer"
              title={'Speed up to 200Mbps'}
              description=""
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <SingleFeaturesCard
              icon="flaticon-speedometer-1"
              title="99% Internet Uptime"
              description=""
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <SingleFeaturesCard
              icon="flaticon-support"
              title="24/7 Customer Support"
              description=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};
