/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PriceCard } from '../Components/PriceCard';

const Broadband = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Accessing query parameters
  const id = queryParams.get('id');
  useEffect(() => {
    if (id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [id]);

  const bsnlPlans = [
    {
      type: 'medium',
      name: 'Fiber Basic Plus',
      price: 599,
      speed: '60Mbps',
      limit: '3300GB',
      mostPopular: true,
      description: '',
      GST: 18,
      data: [
        '60Mbps speed',
        'FUP Limit 3300GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 2Mbps',
      ],
    },
    {
      type: 'medium',
      name: 'Fiber TB Plan',
      price: 777,
      speed: '100Mbps',
      limit: '1000GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '100Mbps speed',
        'FUP Limit 1000GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 5Mbps',
      ],
    },
    {
      type: 'medium',
      name: 'Super Star- Premium1',
      price: 749,
      speed: '100Mbps',
      limit: '1000GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '100Mbps speed',
        'FUP Limit 1000GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 5Mbps',
      ],
      ott: ['SonyLIV Premium', 'Zee5 Premium', 'Voot Select', 'YuppTV'],
    },
    {
      type: 'medium',
      name: 'Super Star-1',
      price: 779,
      speed: '100Mbps',
      limit: '1000GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '100Mbps speed',
        'FUP Limit 1000GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 5Mbps',
      ],
      ott: ['Hotstar Premium'],
    },
    {
      type: 'medium',
      name: 'Fiber Value',
      price: 799,
      speed: '100Mbps',
      limit: '3300GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '100Mbps speed',
        'FUP Limit 1000GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 2Mbps',
      ],
    },
    {
      type: 'medium',
      name: 'Fiber Value Plus',
      price: 849,
      speed: '100Mbps',
      limit: '1500GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '100Mbps speed',
        'FUP Limit 1500GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 10Mbps',
      ],
    },
    {
      type: 'medium',
      name: 'Super Star- Premium2',
      price: 949,
      speed: '150Mbps',
      limit: '2000GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '150Mbps speed',
        'FUP Limit 2000GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 10Mbps',
      ],
      ott: ['SonyLIV Premium', 'Zee5 Premium', 'Voot Select', 'YuppTV'],
    },
    {
      type: 'medium',
      name: 'Fiber Premium',
      price: 999,
      speed: '200Mbps',
      limit: '3300GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '200Mbps speed',
        'FUP Limit 3300GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 2Mbps',
        'Static IP - 2000 p.a',
      ],
      ott: ['Hotstar Premium'],
    },
    {
      type: 'medium',
      name: 'Fiber Premium Plus',
      price: 1277,
      speed: '200Mbps',
      limit: '3300GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: [
        '200Mbps speed',
        'FUP Limit 3300GB',
        'Unlimited local + STD voice call',
        'Speed After FUP 15Mbps',
        'Static IP - 2000 p.a',
      ],
    },
  ];
  const ticFiberPlans_Fup = [
    {
      type: 'medium',
      name: 'Tic-FUB 20Mbps 200GB',
      price: 450,
      speed: '20Mbps',
      limit: '200GB',
      mostPopular: true,
      description: '',
      GST: 18,
      data: ['20Mbps speed', 'FUP Limit 200GB', 'Speed After FUP 2Mbps'],
    },
    {
      type: 'medium',
      name: 'Tic 40Mbps 400GB',
      price: 508,
      speed: '40Mbps',
      limit: '400GB',
      mostPopular: true,
      description: '',
      GST: 18,
      data: ['40Mbps speed', 'FUP Limit 400GB', 'Speed After FUP 2Mbps'],
    },
    {
      type: 'medium',
      name: 'Tic 50Mbps 500GB',
      price: 599,
      speed: '50Mbps',
      limit: '500GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: ['50Mbps speed', 'FUP Limit 500GB', 'Speed After FUP 2Mbps'],
    },
    {
      type: 'medium',
      name: 'Tic 100Mbps 1000GB',
      price: 847,
      speed: '100Mbps',
      limit: '1000GB',
      mostPopular: false,
      description: '',
      GST: 18,
      data: ['100Mbps speed', 'FUP Limit 1000GB', 'Speed After FUP 2Mbps'],
    },
  ];
  const ticFiberPlans_Unlimited = [
    {
      type: 'medium',
      name: 'Tic- 10Mbps',
      price: 423,
      speed: '10Mbps',
      limit: 'Unlimited',
      mostPopular: true,
      description: '',
      GST: 18,
      data: ['10Mbps speed', 'Unlimited'],
    },
    {
      type: 'medium',
      name: 'Tic- 20Mbps',
      price: 508,
      speed: '20Mbps',
      limit: 'Unlimited',
      mostPopular: true,
      description: '',
      GST: 18,
      data: ['20Mbps speed', 'Unlimited'],
    },
    {
      type: 'medium',
      name: 'Tic- 50Mbps',
      price: 669,
      speed: '50Mbps',
      limit: 'Unlimited',
      mostPopular: false,
      description: '',
      GST: 18,
      data: ['50Mbps speed', 'Unlimited'],
    },
    {
      type: 'medium',
      name: 'Tic- 100Mbps',
      price: 999,
      speed: '100Mbps',
      limit: 'Unlimited',
      mostPopular: false,
      description: '',
      GST: 18,
      data: ['100Mbps speed', 'Unlimited'],
    },
  ];
  return (
    <>
      <section className="pricing-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
              <div className="banner-content">
                <h1>Superfast fiber is here, Book today</h1>
                <p className="mt-5 mb-0">
                  Work from home with Unlimited data plan on Tamil Nadu's fastest wired broadband
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="banner-image">
                <img src="img/plan-banner-img.gif" alt="banner-image" className="plan-banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="bsnl">
          <div className="section-title">
            {/* <span>
              <span className="icon">
                <i className="flaticon-resume"></i>
              </span>
              <span>Pricing</span>
            </span> */}
            <h2>Discover our best plans</h2>
            <h4>BSNL Broadband plans</h4>
          </div>
          <div className="row pricing-plans">
            {Array.isArray(bsnlPlans) &&
              bsnlPlans.map((plan) => {
                return (
                  <PriceCard
                    name={plan.name}
                    data={plan.data}
                    price={plan.price}
                    GST={plan.GST}
                    ott={plan.ott}
                    mostPopular={plan.mostPopular}
                  />
                );
              })}
          </div>
        </div>
        <Tic
          ticFiberPlans_Fup={ticFiberPlans_Fup}
          ticFiberPlans_Unlimited={ticFiberPlans_Unlimited}
        />
      </section>
    </>
  );
};

const Tic = ({ ticFiberPlans_Fup, ticFiberPlans_Unlimited }) => {
  return (
    <div className="container" id="tic">
      <div className="section-title">
        <h2>Discover our Tic Fiber</h2>
        <h4> Unlimited plans</h4>
      </div>
      <div className="row pricing-plans">
        {Array.isArray(ticFiberPlans_Unlimited) &&
          ticFiberPlans_Unlimited.map((plan) => {
            return (
              <PriceCard
                name={plan.name}
                data={plan.data}
                price={plan.price}
                GST={plan.GST}
                ott={plan.ott}
                mostPopular={plan.mostPopular}
              />
            );
          })}
      </div>
      <div className="section-title">
        <h4> FUP plans</h4>
      </div>
      <div className="row pricing-plans">
        {Array.isArray(ticFiberPlans_Fup) &&
          ticFiberPlans_Fup.map((plan) => {
            return (
              <PriceCard
                name={plan.name}
                data={plan.data}
                price={plan.price}
                GST={plan.GST}
                ott={plan.ott}
                mostPopular={plan.mostPopular}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Broadband;
