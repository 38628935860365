import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Services = () => {
  const navigate = useNavigate();
  const handleClick = (pathName) => {
    navigate(pathName);
  };
  return (
    <section className="services-area bg-image ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>Explore Our Services</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box" onClick={(e) => handleClick('/cableTv')}>
              <div className="icon">
                <i className="flaticon-wifi-1"></i>
              </div>
              <h3>Cable TV</h3>
              {/* <p>We are providing cable Tv services</p> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box" onClick={(e) => handleClick('/broadband')}>
              <div className="icon">
                <i className="flaticon-router"></i>
              </div>
              <h3>Broadband</h3>
              {/* <p>We are providing broadband services</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
