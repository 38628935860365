import React from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import { Footer } from './Components/Footer';
import { Navbar } from './Components/Navbar';
import Home from './Pages/Home';
import Contacts from './Pages/Contacts';
import Blog from './Pages/Blog';
import AboutUs from './Pages/AboutUs';
import SingleBlog from './Pages/SingleBlog';
import Broadband from './Pages/Broadband';
import CableTv from './Pages/CableTv';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contacts />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/broadband" element={<Broadband />} />
        <Route exact path="/cableTv" element={<CableTv />} />
        <Route exact path="/blog/:id" element={<SingleBlog />} />
        <Route exact path="/aboutUs" element={<AboutUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
