import React from 'react';
import { MainBanner } from '../Components/Main_banner';
import { About } from '../Components/About';
import { Features } from '../Components/Features';
import { Services } from '../Components/Services';

const Home = () => {
  return (
    <>
      <MainBanner />
      <Features />
      <Services />
      <About />
    </>
  );
};
export default Home;
