import React from 'react';

export const About = (props) => {
  const { showBtn } = props;
  return (
    <section className="about-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <span className="sub-title">
                <i className="flaticon-care-about-environment"></i>
                About Raj Cable TV
              </span>
              <h2>Experience the magic of tech to serve you a best</h2>
              <p>
                Welcome to Raj Cable TV, We have 25+ years experience in cable TV and 4+ years
                experience in internet service. We always upgrade out technical system time to time
                for enhancing our customer experience and satisfaction. Thanks for using our
                reliable service. we are doing best services in our area.
              </p>
              {/* <a className="btn btn-primary" href="/about-us-1">
                Learn More
              </a> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src="img/about-img1.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
