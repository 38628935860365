export const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="copyright-area">
        <div className="container">
          <p>
            <i className="far fa-copyright"></i> Copyright Raj Cable TV & Broadband 2022 All Rights
            Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};
