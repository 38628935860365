import React, { useState } from 'react';
import { About } from '../Components/About';

const AboutUs = () => {
  return (
    <>
      <section className="about-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>Raj Cable TV is giving Best Best service away</h2>
          </div>
          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-about-box">
                  <div className="icon">
                    <i className="flaticon-support"></i>
                  </div>
                  <h3>Fully Staffed 24/7</h3>
                  {/* <p>
                    We are accessible through 24x7 call centers and a team with associates who are
                    skilled to provide you with the best possible answer to all your queries.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-about-box">
                  <div className="icon">
                    <i className="flaticon-router"></i>
                  </div>
                  <h3>Get High Speed Internet</h3>
                  {/* <p>
                    We are giving broadband internet service to the users. You can watch TV channels
                    on TV and surf internet on computer at same time.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div className="single-about-box about-details">
                  <div className="icon">
                    <i class="fa fa-wrench"></i>
                  </div>
                  <h3>Maintenance</h3>
                  {/* <p>We are doing free of cost for resolving faults.</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">{<About showBtn={false} />}</div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
