import React from 'react';
import { Link } from 'react-router-dom';

export const MainBanner = () => {
  return (
    <>
      <div className="main-banner">
        <div className="container">
          <div className="row align-items-center m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="main-banner-content">
                <span className="sub-title">
                  <i className="flaticon-wifi-signal"></i>
                  {/* <span class="material-icons-outlined">wifi</span> */}
                  {`Raj Cable TV & Broadband Service`}
                </span>
                <h1>
                  Built for Internet
                  <span> Service</span>
                </h1>
                <p>
                  We are providing Cable TV service and we are also providing FTTH internet service
                  in serndamarm.
                </p>
                <div className="price">
                  &#x20b9;599*
                  <span>/mo</span>
                </div>
                <div className="btn-box">
                  <Link to={'/contact'} className="btn btn-primary">
                    Request A Quote
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="banner-image">
                <img src="img/banner-img1.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img1">
          <img src="img/1.png" alt="imgae" />
        </div>
      </div>
    </>
  );
};
