import React, { useState } from 'react';
import { getWhatsappLink } from '../utils';

const getPriceWithGST = (price, GST) => {
  if (typeof price !== 'number' && typeof GST !== 'number') {
    return price;
  }
  return Math.round(price * ((100 + GST) / 100));
};

const getOttImage = (ottName) => {
  switch (ottName) {
    case 'SonyLIV Premium':
      return (
        <>
          <img src="img/ott/sony.jpg" alt={ottName} name={ottName} className="ott-img-icon p-1" />
        </>
      );
    case 'Zee5 Premium':
      return (
        <img src="img/ott/zee5.png" alt={ottName} name={ottName} className="ott-img-icon p-1" />
      );
    case 'Voot Select':
      return (
        <img src="img/ott/voot.png" alt={ottName} name={ottName} className="ott-img-icon p-1" />
      );
    case 'YuppTV':
      return (
        <img src="img/ott/yupp_tv.png" alt={ottName} name={ottName} className="ott-img-icon p-1" />
      );
    case 'Hotstar Premium':
      return (
        <img src="img/ott/hotstar.jpg" alt={ottName} name={ottName} className="ott-img-icon" />
      );
    default:
      return null;
  }
};

const sendWhatsappMsg = (planName) => {
  const whatsappLink = getWhatsappLink(`Hi, I need this plan ${planName}`);
  window.open(whatsappLink, '_blank');
};

export const PriceCard = (props) => {
  const { name, data, price, GST, ott, mostPopular } = props;
  const [showAmountWithGST, setShowAmountWithGST] = useState(false);
  const amount = getPriceWithGST(price, GST);
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 price-card-height match-height">
      <div className="single-pricing-table active ">
        <div className="pricing-header">
          <div className="pricing-icon">
            <div className="icon">
              <i className="flaticon-online-shop"></i>
            </div>
            <span>{mostPopular && 'mostPopular'}</span>
          </div>

          <h3>{name}</h3>
        </div>
        <div className="pricing-features-height">
          <ul className="pricing-features-list">
            {Array.isArray(data) &&
              data.map((t) => {
                return (
                  <li>
                    <i className="flaticon-check-mark"></i> {t}
                  </li>
                );
              })}
          </ul>
          {Array.isArray(ott) && ott.length > 0 && (
            <div className="ott-features">
              <span>OTT Apps</span>
              {ott.map((app) => {
                return getOttImage(app);
              })}
            </div>
          )}
        </div>

        <div className="price">
          <span>From</span>
          {/* <span>&#x20b9;</span> {price}*{' '} */}
          {showAmountWithGST ? (
            <>
              <span>&#x20b9;</span> {amount}*<span>/month</span>
            </>
          ) : (
            <>
              <span>&#x20b9;</span> {price}*{' '}
              <span>/month {GST !== 0 && <small>(Extra GST {GST}%) </small>}</span>
            </>
          )}
        </div>
        <div className="price-card-action">
          {!showAmountWithGST && GST !== 0 && (
            <a
              className="view-plans-btn"
              style={{ marginRight: '10px' }}
              onClick={() => setShowAmountWithGST(true)}
            >
              Price With GST
            </a>
          )}
          <a className="view-plans-btn" onClick={() => sendWhatsappMsg(name)}>
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
};
