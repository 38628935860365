import React, { useState } from 'react';
import { getWhatsappLink } from '../utils';

const Contacts = () => {
  const [message, setMessage] = useState('');

  const sendWhatsappMsg = () => {
    const whatsappLink = getWhatsappLink(message);
    window.open(whatsappLink, '_blank');
  };

  return (
    <>
      <section className="contact-info-box-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-contact-info">
                <div className="icon">
                  <i className="flaticon-place"></i>
                </div>
                <h3>Address</h3>
                <p>122/3, Ground floor, Main road, Serndamaram - 627857</p>
                <div className="image-box">
                  <img src="assets/img/shape-image/1.png" alt="image" />
                  <img src="assets/img/shape-image/1.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-contact-info">
                <div className="icon">
                  <i className="flaticon-address-book"></i>
                </div>
                <h3>Email</h3>
                <p>
                  <a href="mailto:ponseelan.11@gmail.com">rajbroadbandsendamaram@gmail.com</a>
                </p>
                <div className="image-box">
                  <img src="assets/img/shape-image/1.png" alt="image" />
                  <img src="assets/img/shape-image/1.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="single-contact-info">
                <div className="icon">
                  <i className="flaticon-signal"></i>
                </div>
                <h3>Phone</h3>
                <p>
                  <a href="tel:+917373622303">+91 7373622303</a>
                </p>
                {/* <p>
                  <a href="tel:+917010330108">+91 7010330108</a>
                </p> */}
                <div className="image-box">
                  <img src="assets/img/shape-image/1.png" alt="image" />
                  <img src="assets/img/shape-image/1.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Send Message</span>
            <h2>Drop us message for any query</h2>
          </div>
          <form id="contactForm">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Type Your Message Here"
                    className="form-control"
                    required={true}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <button type="submit" className="btn btn-primary" onClick={() => sendWhatsappMsg()}>
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <div id="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.2491581987628!2d77.43707180734023!3d9.064990800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b069bf5bd765c13%3A0x3245dfb24f47b377!2sRaj%20Cable%20TV%20%26%20Broadband!5e0!3m2!1sen!2sin!4v1649761060622!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contacts;
