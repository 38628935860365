/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const WiFiPasswordBlog = () => {
  return (
    <div className="col-lg-12 col-md-12">
      <h1 className="blog-title">How to changes WIFI Name and Password</h1>
      <div className="blog-details">
        {/* <div className="article-image">
		<img src="img/banner-img1.png" alt="image" />
	</div> */}
        <div className="article-content">
          <p>
            It is important to change your default WiFi name and password for security reasons. Your
            WiFi’s default password is not strong enough to protect you from hackers, who might be
            able to find or guess your login information and steal your personal information (such
            as your bank details). Plus, it’s easier to remember your WiFi password when you create
            it yourself. Here’s how to how to change your WiFi network’s name and password on your
            Windows 10 or Mac computer.
          </p>

          <h5>Before You Can Start:</h5>
          <p>
            Before you can change your WiFi name and password, you need to log in to your router.
            That means you will have to be connected to your WiFi network and know your router’s IP
            address, as well as its username and password. If you forgot your WiFi password, you can
            find it on any computer connected to that wireless network. Check out our step-by-step
            guides on how to find your WiFi password on a Windows 10 PC and how to find any
            passwords on a Mac.
          </p>

          <h3>How to Change Your WiFi Name and Password</h3>
          <p>
            To change your WiFi name and password, open a web browser and type your router’s IP
            address into the address bar.
          </p>
          <p>
            <b>1.Open a web browser </b>
            <span>You can use Chrome, Firefox, Safari, or any other web browser</span>
          </p>
          <p>
            <b>2.Then type your router’s IP address into the search bar and press the Enter key.</b>{' '}
            {''}
            <span>
              {''}you can try entering “192.168.1.1” into the address bar of your browser.
            </span>
          </p>
          <p>
            <b>3.Next, enter your router’s username and password and click LogIn</b> {''}
            <span>
              {''}
              Your router’s username and password are not the same as your WiFi username and
              password. You might be able to find your router’s username and password printed
              somewhere on your router or in its manual. If not, you can also ask operator
            </span>
            <div className="article-image">
              <img
                // src={p1}
                src="/img/blog/b1/p1.png"
                alt="image"
              />
            </div>
          </p>
          <p>
            <b>
              {' '}
              4. To change WiFi Name, click Network {'->'} WLAN {'->'} WLAN Basic{' '}
            </b>
            <span>
              {''}
              In that form "SSID" text box Enter your WiFi Name then click "Apply Change" button
            </span>
            <div className="article-image">
              <img src="/img/blog/b1/p3.png" alt="image" />
            </div>
          </p>
          <p>
            <b>
              5.To change WiFi Password, click Network {'->'} WLAN {'->'} WLAN Security
            </b>
            <span>
              In that form "Pre-Shared key" text box, Enter your WiFi Password then click "Apply
              Change" button
            </span>
            <div className="article-image">
              <img src="/img/blog/b1/p4.png" alt="image" />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

const SingleBlog = () => {
  let { id } = useParams();
  console.log(id);
  const getCurrentBlog = (blogId) => {
    if (blogId === 'how-to-change-wifi-password') {
      return <WiFiPasswordBlog />;
    }
    return null;
  };
  const navigate = useNavigate();
  return (
    <section className="blog-details-area ptb-100">
      <div className="container">
        <button onClick={() => navigate(-1)}>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          {' Back'}
        </button>
        <div className="row">{<>{getCurrentBlog(id)}</>}</div>
      </div>
    </section>
  );
};

export default SingleBlog;
